(function() {
  var app = angular.module("app");

  app.controller('ContactController', ['$scope', '$rootScope', '$http', 'functions', function($scope, $rootScope, $http, functions) {
    $scope.data = {
      name: '',
      email: '',
      subject: '',
      message: ''
    };

    $scope.setResponse = function(response) {
      $scope.gRecaptchaResponse = response;
    }

    $scope.canSave = function() {
      return $scope.contactUsForm.$dirty && $scope.contactUsForm.$valid && $scope.gRecaptchaResponse;
    }

    $scope.send = function(e) {
      wait("Sending", "This dialog will close automatically.");

      $http({
        method: 'POST',
        url: '/content/send_email',
        data: jQuery.param(angular.copy(angular.extend($scope.data, { 'g-recaptcha-response': $scope.gRecaptchaResponse }))),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).
      success(function(data, status, headers, config) {
        $scope.data = [];
        success("Message Sent", "Your message was sent successfully.", true, null);
      }).
      error(function(data, status, headers, config) {
        error("Error Sending Message", "Please try again shortly.", true, null);
      });
    };
  }]);
})();
